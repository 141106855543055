@import "fonts.module";

/*
 * Braun base colors
 */
$braun-blue: rgb(64, 144, 205);
// $braun-red: rgb(152, 1, 1);
// $braun-green: rgb(149, 255, 144);
// $braun-purple: rgb(249, 144, 255);
$braun-white: rgb(253, 253, 253);
// $braun-peach: rgb(255, 194, 144);
// $braun-yellow: rgb(249, 255, 144);
$braun-text: rgb(21, 120, 190);

/*
 * Monochromatics
 */
// $braun-very-dark: darken($braun-blue, 80%);
// $braun-dark: darken($braun-blue, 60%);
// $braun-medium-dark: darken($braun-blue, 40%);
// $braun-light-blue: lighten($braun-blue, 40%);
// $braun-dark-green: darken($braun-green, 50);

/*
 * This is where we define our theme base colors
 * using the above values
 */
$braun-background: rgb(205, 219, 235);
// $braun-complementary: $braun_green;
// $braun-dark: darken($braun-blue, 80%);
// $braun-complementary-bright: $braun-red;

/*
 * Overrides of Bootstrap backgrounds
 */
// scss-docs-start theme-colors-map

/*
 * We will use the default Bootstrap background colors.
 * However we will define $dark to be Braun blue.
 */

// $primary: $braun-blue;
// $secondary: $braun-peach;
// $success: darken($braun-green, 25%);
// $info: $braun-peach;
// $warning: $braun-purple;
// $danger: lighten($braun-red, 25%);
// $light: $braun-white;
$dark: rgb(18, 60, 102);

/*
 * Component Overrides for React Bootstrap sidebar menu
 */

// $sidebar-brand-bg: $braun-dark;
// $sidebar-nav-link-hover-color: $braun-text;
// $sidebar-bg: $braun-dark;

// $header-bg: darken($braun-blue, 10%);
// $header-color: $braun-text;

// $tooltip-bg: $braun-yellow;
// $tooltip-arrow-color: $tooltip-bg;
// $tooltip-color: black;

// Color of the caret icon used by the sidebar menu in light and dark themes.
$sidebar-menu-nav-icon-dark-color: white !default;
$sidebar-menu-nav-icon-light-color: black !default;
